var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      attrs: {
        align: "right",
      },
    },
    [
      _c(
        "span",
        {
          staticClass: "auto-refresh-text",
        },
        [_vm._v(_vm._s(_vm.$t("auto_refresh")) + "：")]
      ),
      _c("vue-switch", {
        attrs: {
          type: "text",
          variant: "success",
          pill: "",
          on: "On",
          off: "Off",
        },
        model: {
          value: _vm.autoRefresh,
          callback: function ($$v) {
            _vm.autoRefresh = $$v
          },
          expression: "autoRefresh",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }