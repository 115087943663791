var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "mt-3",
      staticStyle: {
        overflow: "auto",
      },
    },
    [
      _c("top-twenty-suggestion-modal"),
      _c("vuetable", {
        ref: "cloudClassroomTable",
        attrs: {
          data: _vm.classroomData,
          "data-manager": _vm.onLoadData,
          "api-mode": false,
          fields: _vm.fields,
          "pre-page": 10,
          "pagination-path": "pagination",
          "track-by": "id",
        },
        on: {
          "vuetable:loaded": function ($event) {
            _vm.CheckVipStatus = true
          },
          "vuetable:loading": function ($event) {
            _vm.CheckVipStatus = false
          },
          "vuetable:pagination-data": _vm.onPaginationData,
        },
        scopedSlots: _vm._u([
          {
            key: "ClassroomTitle",
            fn: function (props) {
              return [
                _c(
                  "span",
                  {
                    staticClass: "badge badge-pill badge-primary",
                  },
                  [_vm._v(_vm._s("Room." + props.rowData.room_id))]
                ),
                _vm._v(" " + _vm._s(props.rowData.title)),
              ]
            },
          },
          {
            key: "ConsultantStatus",
            fn: function (props) {
              return [
                _c("div", [
                  _vm._v(
                    _vm._s(
                      _vm.getConsultantStatus(props.rowData.consultant_status)
                    )
                  ),
                ]),
              ]
            },
          },
          {
            key: "MaterialLink",
            fn: function (props) {
              return [
                _c("material-field", {
                  attrs: {
                    tableData: props.rowData,
                  },
                }),
              ]
            },
          },
          {
            key: "VipName",
            fn: function (props) {
              return [
                props.rowData.vip && props.rowData.class_type === 6
                  ? _c("div", [_vm._v(_vm._s(props.rowData.vip.length))])
                  : props.rowData.vip && props.rowData.vip[0]
                  ? _c("vip-field", {
                      attrs: {
                        "vip-data": props.rowData.vip[0],
                      },
                    })
                  : _vm._e(),
                _vm.showTopTwentySuggestionButton(props.rowData.class_type)
                  ? _c(
                      "b-button",
                      {
                        attrs: {
                          variant: "success",
                          size: "sm",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.openTopTwentySuggestionModal({
                              vip: props.rowData.vip[0],
                              classroomId: props.rowData.id,
                              index: props.rowIndex,
                              row: props,
                            })
                          },
                        },
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: {
                            icon: "angle-down",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "age",
            fn: function (props) {
              return [
                props.rowData.vip && props.rowData.vip[0]
                  ? _c("span", [_vm._v(_vm._s(props.rowData.vip[0].age))])
                  : _vm._e(),
              ]
            },
          },
          {
            key: "ClassNote",
            fn: function (props) {
              return [
                _vm.showNote(props.rowData.class_type)
                  ? _c("div", [_vm._v(_vm._s(props.rowData.note))])
                  : _vm._e(),
              ]
            },
          },
          {
            key: "Access",
            fn: function (props) {
              return [
                props.rowData.class_type === 6
                  ? _c(
                      "div",
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "btn btn-success",
                            attrs: {
                              type: "button",
                              target: "_blank",
                              title: _vm.$t("hostBtnTitle"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.HostClassroom(
                                  props.rowData.id,
                                  props.rowData.access_classroom_token
                                )
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("host")))]
                        ),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "btn btn-success",
                            attrs: {
                              type: "button",
                              target: "_blank",
                              title: _vm.$t("accessBtnTitle"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.AccessClassroom(
                                  props.rowData,
                                  _vm.currentTime
                                )
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("access")))]
                        ),
                      ],
                      1
                    ),
              ]
            },
          },
        ]),
      }),
      _c(
        "div",
        {
          staticClass: "vuetable-pagination ui basic segment grid",
        },
        [
          _c("vuetable-pagination-info", {
            ref: "cloudClassroomPaginationInfo",
            staticClass: "pagination",
            attrs: {
              "info-template": _vm.$t("pagination_info"),
              "no-data-template": _vm.$t("no_relevant_data"),
            },
          }),
          _c("vuetable-pagination-bootstrap", {
            ref: "cloudClassroomPagination",
            staticClass: "float-right",
            attrs: {
              css: _vm.tableCss.paginationBS4,
            },
            on: {
              "vuetable-pagination:change-page": _vm.onChangePage,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }