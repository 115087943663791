var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "mt-3",
    },
    [
      _c(
        "div",
        {
          attrs: {
            align: "right",
          },
        },
        [
          _c(
            "font",
            {
              attrs: {
                color: "blue",
              },
            },
            [_vm._v("NU : New Upgrade")]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          attrs: {
            align: "right",
          },
        },
        [
          _c(
            "font",
            {
              attrs: {
                color: "red",
              },
            },
            [_vm._v("F : First Class")]
          ),
          _vm._v(" /"),
          _c(
            "font",
            {
              attrs: {
                color: "orange",
              },
            },
            [_vm._v(" 7 : New Student")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }