var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "b-modal",
    {
      attrs: {
        size: "lg",
        centered: "",
        "hide-header": "",
        "hide-footer": "",
      },
      on: {
        change: _vm.initData,
      },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _vm.payload
        ? [
            _vm.page === _vm.PAGE.HISTORY
              ? [
                  _c(
                    "h1",
                    {
                      staticClass: "text-center",
                    },
                    [
                      _vm._v(
                        _vm._s(
                          `${_vm.payload.vip.english_name}(${_vm.payload.vip.chinese_name})`
                        )
                      ),
                    ]
                  ),
                  _c(
                    "table",
                    {
                      staticClass: "w-100",
                    },
                    [
                      _c("thead", [
                        _c("tr", [
                          _c("th", [_vm._v("Consultant")]),
                          _c("th", [_vm._v("Material")]),
                          _c("th", [_vm._v("Average")]),
                          _c("th", [_vm._v("Upgrade")]),
                          _c("th", [_vm._v("Operation")]),
                        ]),
                      ]),
                      _c(
                        "tbody",
                        _vm._l(_vm.history, function (data, index) {
                          return _c(
                            "tr",
                            {
                              key: index,
                            },
                            [
                              _c("td", [
                                _vm._v(
                                  _vm._s(`${data.consultant.english_name}`)
                                ),
                              ]),
                              _c("td", [_vm._v(_vm._s(data.material.title))]),
                              _c("td", [
                                _vm._v(_vm._s(`LV. ${data.average_level}`)),
                              ]),
                              _c("td", [
                                _vm._v(_vm._s(`LV. ${data.upgrade_level}`)),
                              ]),
                              _c("td", [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-link text-primary p-0",
                                    on: {
                                      click: function ($event) {
                                        return _vm.showInfo(data)
                                      },
                                    },
                                  },
                                  [_vm._v("more...")]
                                ),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                ]
              : _vm._e(),
            _vm.page === _vm.PAGE.INFO
              ? [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-link text-primary p-0",
                      staticStyle: {
                        top: "-30px",
                        left: "0",
                      },
                      on: {
                        click: function ($event) {
                          _vm.page = "history"
                        },
                      },
                    },
                    [_vm._v("< return to history")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "container bg-white rounded-bottom",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "row",
                          staticStyle: {
                            "background-color": "#f3f1ed",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "col",
                            },
                            [
                              !_vm.isLoading
                                ? _c("radar-chart", {
                                    ref: "chart",
                                    staticClass: "my-5",
                                    attrs: {
                                      "chart-data": _vm.analysis,
                                      options: _vm.options,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "row",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "col bg-blue rounded-top py-2",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex justify-content-center",
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "text-white h3",
                                    },
                                    [_vm._v(_vm._s(_vm.material))]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "row border-dark py-4",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-md-3 d-flex justify-content-center",
                            },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "mr-2",
                                attrs: {
                                  icon: "calendar-minus",
                                  size: "lg",
                                },
                              }),
                              _vm._v(_vm._s(_vm.createdAt)),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-md-3 d-flex justify-content-center",
                            },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "mr-2",
                                attrs: {
                                  icon: "user",
                                  size: "lg",
                                },
                              }),
                              _vm._v(_vm._s(_vm.name.consultant)),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-md-3 d-flex justify-content-center",
                            },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "mr-2",
                                attrs: {
                                  icon: "users",
                                  size: "lg",
                                },
                              }),
                              _vm._v(_vm._s(_vm.name.vip)),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-md-3 d-flex justify-content-center",
                            },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "mr-2",
                                attrs: {
                                  icon: "chart-line",
                                  size: "lg",
                                },
                              }),
                              _vm._v("Lv. " + _vm._s(_vm.averageLevel)),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "row",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "col",
                            },
                            [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.comment.recommend,
                                    expression: "comment.recommend",
                                  },
                                ],
                                staticClass: "w-100 mt-1 mb-3",
                                attrs: {
                                  placeholder: "Suggestions for improvement.",
                                  rows: 6,
                                },
                                domProps: {
                                  value: _vm.comment.recommend,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.comment,
                                      "recommend",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }