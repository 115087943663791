var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticStyle: {
        width: "200px",
        overflow: "hidden",
        "text-overflow": "ellipsis",
        "white-space": "nowrap",
      },
      attrs: {
        title: _vm.tableData.material.title,
      },
    },
    [
      _c("div", [
        _c(
          "span",
          {
            staticClass: "badge badge-primary",
          },
          [_vm._v(_vm._s(_vm.tableData.material.level))]
        ),
        _vm.tableData.material.file
          ? _c(
              "a",
              {
                attrs: {
                  href: _vm.tableData.material.file,
                },
              },
              [_vm._v(" " + _vm._s(_vm.tableData.material.title))]
            )
          : _c("span", [_vm._v(" " + _vm._s(_vm.tableData.material.title))]),
        _c(
          "span",
          {
            staticClass: "badge",
            class: _vm.materialNoteClass(_vm.tableData.class_type),
          },
          [_vm._v(_vm._s(_vm.materialNoteText(_vm.tableData.class_type)))]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }