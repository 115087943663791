var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      class: "card card-accent-" + _vm.type,
    },
    [
      _c(
        "div",
        {
          staticClass: "card-header",
        },
        [_vm._t("header")],
        2
      ),
      _c(
        "div",
        {
          staticClass: "card-body",
        },
        [_vm._t("body")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }